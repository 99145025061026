import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const GuardAuth = ({ component: Component, token: Token, routeRedirect, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem(Token) ?
            <Component {...props} /> :
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
);

export const GuardGuest = ({ component: Component, token: Token, routeRedirect, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem(Token) ?
            <Redirect to={{ pathname: '/me', state: { from: props.location } }} /> :
            <Component {...props} />

    )} />
);