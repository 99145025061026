import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from "./components/pages/HomeComponent";
import Login from "./components/pages/LoginComponent";
import Register from "./components/pages/RegisterComponent";
import Profile from "./components/pages/ProfileComponent";
import { GuardGuest, GuardAuth } from './Guard';
import Header from './components/layouts/Header';

function Routes() {
    return (
        <>
            <Header />
            <Switch>

                <Route exact path="/" render={props => (
                    <Redirect to={{ pathname: '/home' }} />
                )} />

                <Route path="/home" component={Home} />
                {/*Redirect if authenticated */}
                <GuardGuest path="/login" token="user-token" component={Login} />
                <GuardGuest path="/register" token="user-token" component={Register} />
                {/*Redirect if not authenticated */}
                <GuardAuth path="/me" token="user-token" component={Profile} />
            </Switch>
        </>
    );
}
export default Routes;