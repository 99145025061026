import React, { useEffect } from 'react';
import { Card } from '@material-ui/core';
import { useStyles } from '../../styles/styles';
import { useDispatch, useSelector } from 'react-redux';
import { LoadProfileAction } from '../../redux/actions/ProfileActions';
export default function ProfileView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const profileResponse = useSelector(state => state.userDetails.userProfile);
    useEffect(() => {
        dispatch(LoadProfileAction());
        return () => {
        };
    }, [])
    return (
        <div className={classes.fullWidthProfile}>
            <Card>
                {
                    profileResponse !== "" && profileResponse !== null && profileResponse.success === true ?
                        <div>
                            <h3><b>Username: {profileResponse.data.username}</b></h3>
                            <h3><b>Email: {profileResponse.data.mail}</b></h3>
                            <h3><b>Credits: ${profileResponse.data.credits}</b></h3>
                        </div>
                        :
                        <div>Unable to display profile</div>
                }
            </Card>
        </div>
    );
}